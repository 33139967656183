import React, { useState } from 'react';
import Nav from '../components/nav';
import Footer from '../components/footer';

function Btc(props) {
    const [amount, setAmount] = useState()
    const [coin] = useState(JSON.parse(localStorage.getItem('plan')))
    return (
        <div>
              <div className='app'>

<div className='main-body'>
    <div className="plan_bg">
        <Nav />
        <br />
        <h4 className="faint text-center">Deposit Funds</h4>

        <form autoComplete="false" className='mx-4'>

            <input type="number" className=' mt-3 form-control  ' placeholder='Enter amount($)' autoComplete="off"
                value={amount} onChange={(e) => { setAmount(e.target.value) }}
            />
            <input type="text" className=' mt-3 form-control ' placeholder='coin' />
         

        </form>
    </div>
    <Footer />
</div>
</div>
        </div>
    );
}

export default Btc;