import React, { useRef, useState } from "react";
import Earnings from "../balances/earnings";
import Referal from "../balances/referal";
import Nav from "../components/nav";
import Footer from "../components/footer";
import swal from "sweetalert";
import useAuth from "../hooks/auth";
import { auth } from "../config";
import Loading from "./Loading";
import { useAuthState } from "react-firebase-hooks/auth";
import emailjs from "@emailjs/browser";
import { db } from "../config";
import { Spinner, useToast } from "@chakra-ui/react";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Active from "../balances/active";

function Withdraw() {
  const { user, isLoading: authLoading } = useAuth();
  const [authUser, error] = useAuthState(auth);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionPayment, setSelectedOptionPayment] = useState("");
  const [Amount, setAmount] = useState("");
  const [Referalmount, setReferalmount] = useState("");
  const [Address, setAddress] = useState("");
  const [rb, setrb] = useState(true);
  const [ac, active] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const nav = useNavigate();

  const handleSelectChangePayment = (e) => {
    setSelectedOptionPayment(e.target.value);
  };
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    if (selectedOption == "Active") {
      active(true);
    }
    if (selectedOption != "Active") {
      active(false);
    }
    if (selectedOption === "Referal") {
      setrb(true);
    } else {
      setrb(false);
    }
  };
  const AmountChange = (event) => {
    setAmount(event.target.value);
    setReferalmount("");
    if (Amount > parseInt(user.earnings)) {
      alert("Cannot exceed total earnings!");
      setAmount(parseInt(user.earnings));
    }
  };
  const ReferalmountChange = (event) => {
    setReferalmount(event.target.value);
    setAmount("");
    if (Referalmount > parseInt(user.referalBalance)) {
      alert("Cannot exceed total earnings!");
      setReferalmount(parseInt(user.referalBalance));
    }
  };

  const form = useRef();
  const withdrawal = (e) => {
    e.preventDefault();
    if (!Referalmount && !Amount) {
      toast({
        title: "Error",
        description: "Invalid Inputs!",
        status: "error",
        duration: "3000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    } else if (!Address) {
      toast({
        title: "Error",
        description: "provide a valid wallet address",
        status: "error",
        duration: "3000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    } else if (selectedOption == "Active") {
      toast({
        title: "Error",
        description:
          "Total deposits must be atleadt $100 before you can withdraw",
        status: "error",
        duration: "3000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    } else if (
      Amount > parseInt(user.earnings) ||
      Referalmount > parseInt(user.referalBalance)
    ) {
      toast({
        title: "Error",
        description:
          "You cannot withdraw an amount greater than your total balanace",
        status: "error",
        duration: "7000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    } else if (selectedOptionPayment == "") {
      toast({
        title: "Error",
        description: "Please select a payment method",
        status: "error",
        duration: "7000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    } else if (parseInt(user.earnings) < 100 && selectedOption == "Earnings") {
      toast({
        title: "Error",
        description:
          "Total earnings are only withdrawable once the minimum amount($100) is reached",
        status: "error",
        duration: "7000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    } else if (
      parseInt(user.referalBalance) < 100 &&
      selectedOption == "Referal"
    ) {
      toast({
        title: "Error",
        description:
          "Your referal balance is only withdrawable once the minimum amount($100) is reached",
        status: "error",
        duration: "7000",
        isClosable: true,
        position: "top",
        variant: "subtle",
      });
      return;
    }

    setLoading(true);
    emailjs
      .sendForm(
        // "service_x832uyh", mine email
        "service_er1im15",
        // "template_6ciy15u", mine
        "template_1b24srf",
        form.current,
        // "sdGdGhn9jRUjRh2cE" mine
        "hci5cfxRxa1D1BoTE"
      )
      //1 SERVICE ID
      //2 TEMPLATE_ID
      //3 PUBLIC KEY
      .then(
        (result) => {
          console.log(result.text);

          setDoc(doc(db, "Withdrawals", user?.id), {
            email: user?.email,
            earnings: user?.earnings,
            EarningsAmount: Amount,
            referalAmount: Referalmount,
            wallet_address: Address,
            created_at: serverTimestamp(),
          });

          toast({
            title: "Success",
            description: "Your request is being processed",
            status: "success",
            duration: "5000",
            isClosable: true,
            position: "top",
            colorScheme: "blue",
            variant: "subtle",
          });
          setLoading(false);
          nav("/dashboard");
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
          swal("", "An error occured", "info");
        }
      );
  };

  if (!user) return <Loading />;
  return (
    <div className="app">
      <div className="main-body">
        <div className="text-white ">
          <Nav />
          <div className="withdrawables steps">
            {/* <Active /> */}
            <br />
            <Earnings />
            <br />
            <Referal />
            <br />
            <div className="mx-4 mt-3">
              <form ref={form}>
                <select
                  className="form-select"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="Earnings">Earnings</option>
                  {/* <option value="Active">Active deposits</option> */}
                  <option value="Referal">Referal Balance</option>
                </select>
                <br />
                <label>Enter Amount:</label>
                <input
                  type={rb ? "number" : "hidden"}
                  className="form-control"
                  placeholder="Earnings"
                  value={Amount}
                  onChange={AmountChange}
                />
                <input
                  type={!rb ? "number" : "hidden"}
                  className="form-control"
                  placeholder="referal balance"
                  value={Referalmount}
                  onChange={ReferalmountChange}
                />
                {/* {ac && (
                  <input
                    type={!rb ? "number" : "hidden"}
                    className="form-control"
                    placeholder="Active deposits"
                  />
                )} */}

                <br />
                <label>Wallet Address</label>
                <input
                  type="text"
                  className="form-control"
                  value={Address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
                <br />
                <label>Payment method:</label>
                <select
                  className="form-select"
                  value={selectedOptionPayment}
                  onChange={handleSelectChangePayment}
                >
                  <option selected value="">
                    select payment method
                  </option>
                  <option value="usdt">USDT(TRC20)</option>
                </select>
                <input
                  type="hidden"
                  name="message"
                  value={
                    user?.name +
                    " is requesting " +
                    selectedOption +
                    " withdrawal of " +
                    Amount +
                    " " +
                    Referalmount +
                    " to the following wallet address " +
                    Address
                  }
                />
                {loading ? (
                  <div className="mt-4">
                    <Spinner thickness="5px" />
                  </div>
                ) : (
                  <button className="btn btn-light mt-4" onClick={withdrawal}>
                    Request withdrawal
                  </button>
                )}
              </form>
            </div>
            <br />
            <br />
            <br />
          </div>
          <Footer />
          <br />
        </div>
      </div>
    </div>
  );
}
export default Withdraw;
