import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC08GuvdPgSaEizzgl8l3PAqUwTF8WeKwg",
  authDomain: "ultimatevest.firebaseapp.com",
  projectId: "ultimatevest",
  storageBucket: "ultimatevest.appspot.com",
  messagingSenderId: "509478760042",
  appId: "1:509478760042:web:9f24dc27cfc0b15a5fa413",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const firebaseConfig = {
//   apiKey: "AIzaSyBUrQ4y1D0d8LwMwDqsnAJIzY2SMGJRuYw",
//   authDomain: "wallets-54299.firebaseapp.com",
//   projectId: "wallets-54299",
//   storageBucket: "wallets-54299.appspot.com",
//   messagingSenderId: "695472225066",
//   appId: "1:695472225066:web:c9602790c742beafb33c53",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore();

export const storage = getStorage();

export const auth = getAuth();
