import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { Lock } from '@mui/icons-material';
function ModalDispaly({ isOpen, onClose }) {
    return (
        <div className=''>
            <Modal isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent style={{ marginTop: '240px', width: '97%', height:'35%' }}>
                    <center className='modalHeader'>
                        <ModalHeader  ><h3>WalletConnect</h3></ModalHeader>
                        <ModalCloseButton />
                    </center>
                    <ModalBody className='mt-3 ' >
                        <center>
                            <Link to="/wallets">
                                <button className="btn modal-btn">Proceed To Choose Wallet</button>
                            </Link>
                            <div className='mt-4' style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <small className='faint'><Lock fontSize='inherit'/>This session is Secured and encrypted.</small>
                            </div>
                        </center>
                    </ModalBody>


                </ModalContent>

            </Modal>
        </div>
    );
}

export default ModalDispaly;