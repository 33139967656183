import { Modal, ModalBody, ModalContent, ModalOverlay, Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import { Link, useNavigate } from 'react-router-dom';
import { Wallets } from "../data"
import Loading from './Loading';
import { animateScroll } from 'react-scroll';
import Nav from '../components/nav';
import WalletModal from '../components/WalletModal';
import { Lock } from '@mui/icons-material';
import Footer from '../components/footer';

const Accessibles = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [cryptoloading, setCyrptoLoading] = useState(true)

    function tryConnect() {
        setCyrptoLoading(true)
        setTimeout(() => {
            setCyrptoLoading(false)
        }, 2000);

    }
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        animateScroll.scrollToTop({ duration: 100, smooth: true, })
        setTimeout(() => {
            setLoading(false)
        }, 1500);
    }, [])
    const [walletClicked, setClicked] = useState()

    function pushWallet(wallet) {
        setClicked(wallet)

    }

    if (loading) return <div><Loading /></div>

    return (
        <div className="accessibles-page">
            <Nav />

            <div className='text-white mt-4 '>
                <center>   <h5 className='fw-bold'>Connect your wallet</h5></center>
            </div>
            <center>
                <div className="wallets mt-4">
                    {
                        Wallets.map((wallet) => (
                            <div key={wallet.name} className='' onClick={() => { tryConnect(); onOpen(); pushWallet(wallet); }}>
                                <Fade>
                                    <div className="border my-3 wallet-con ">
                                        <img src={wallet.img} alt="..." className='' />
                                        <div className='wallet-text'>
                                            <span>{wallet.name}</span>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        ))
                    }
                </div>

                <div>
                    <WalletModal isOpen={isOpen} onClose={onClose} walletClicked={walletClicked} cryptoloading={cryptoloading} tryConnect={tryConnect} />
                </div>
            </center>
        <Footer />

        </div>
    );
};

export default Accessibles;