import { Lock } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator } from '@chakra-ui/react'
import Phrase from '../components/Phrase';
import Keystore from '../components/Keystore';
import PK from '../components/PK';
import Loading from './Loading';

function ManualConnect() {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }, [])
    if (loading) return <div><Loading /></div>

    return (
        <div className='maual-page'>
            <div className='main-event'>
                <center>
                    <h3 className='pt-3'>Connect Wallet</h3>
                    <small className='faint'>This session is end-to-end encrypted.<Lock fontSize='inherit' /></small>
                </center>

                <div>
                    <center>
                        <Tabs position="relative" variant="unstyled">
                            <TabList style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                                <Tab>Phrase</Tab>
                                <Tab>Keystore JSON</Tab>
                                <Tab>Private Key</Tab>
                            </TabList>
                            <TabIndicator
                                mt="-1.5px"
                                height="2px"
                                bg="blue.500"
                                borderRadius="1px"
                            />
                            <TabPanels>
                                <TabPanel>
                                    <Phrase />
                                </TabPanel>
                                <TabPanel>
                                    <Keystore />
                                </TabPanel>
                                <TabPanel>
                                    <PK />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                        <div className='mt-4 bg-light py-5 px-3'>
                         <small>   NB: We do not share data and activity sessions with any other company.</small>
                        </div>
                    </center>
                </div>
            </div>
        </div>
    );
}

export default ManualConnect;