import React from 'react';
import Nav from '../components/nav';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import Footer from '../components/footer';
import { coins } from '../data';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import bit from '../assets/bit.jpg'
import bnb from '../assets/bnb.jpg'
import { useState } from 'react';
import Loading from './Loading';

function Coin(props) {

    const nav = useNavigate()
    const toast = useToast()
    const addCoin = (coin) => {
        console.log(coin)
        localStorage.setItem("coin", JSON.stringify(coin))
        toast({
            title: 'Coin selected',
            status: 'success',
            duration: '2500',
            isClosable: true,
            position: 'top',
            colorScheme: 'gray',
            variant: 'subtle'
        })
    }

    const [loaded, setLoaded] = useState(false)
    const [loaded1, setLoaded1] = useState(false)
    useEffect(() => {
        const img = new Image;
        const img1 = new Image;
        img.onload = () => setLoaded(true)
        img1.onload = () => setLoaded1(true)
        img.src = bnb
        img1.src = bit
    }, [])
    if (!loaded || !loaded1) return <div><Loading /></div>

    return (
        <div className='app'>
            <div className='main-body'>
                <div className="plan_bg">
                    <div className="choose-coin text-white">
                        <Nav />
                        <br />
                        <center>
                            <h4>SELCECT COIN</h4>
                            <br />
                            <div className="coin-display mx-2">
                                {
                                    coins.map((coin) => (
                                        <div key={coin.name} onClick={() => addCoin(coin)}>
                                            <Link to={coin.page}>
                                                <div className='overlay-body'>
                                                    <div className='overlay'>
                                                        <h5 className='fw-bold mt-1'>  {coin.name}</h5>
                                                    </div>
                                                    <img src={coin.img} alt="..." className='img-fluid ' />
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>
                        </center>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Coin;