import React from "react";
import {
  AccountCircle,
  CloseOutlined,
  CurrencyBitcoin,
  CurrencyExchange,
  CurrencyYen,
  Dashboard,
  Engineering,
  Home,
  Login,
  Logout,
  Menu,
} from "@mui/icons-material";
import { Fade } from "react-reveal";
import { Link, useNavigate } from "react-router-dom";
import { useLogout } from "../hooks/logout";
import useAuth from "../hooks/auth";
import { Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { CoinSvg2 } from "./coinSvg";
import { Usetrade } from "../hooks/useCheck";
import { auth } from "../config";
import { useAuthState } from "react-firebase-hooks/auth";

function Nav(props) {
  const { logout, isLoading } = useLogout();
  const [isAdmin, setAdmin] = useState(false);
  const nav = useNavigate();
  const { user, isLoading: pending } = useAuth();
  const { trade, loading: tradeLoading } = Usetrade();
  const [authUser, error] = useAuthState(auth);

  const styles = {
    logo: {
      letterSpacing: "3px",
      fontSize: "20px",
    },
  };
  return (
    <div>
      <Fade top duration={1500}>
        <div className="main-nav">
          <Link to="/">
            <div>
              <h3 className="TILT" style={styles.logo}>
                ULTIMATEVEST
              </h3>
            </div>
          </Link>

          <div
            className="text-white btn"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <Menu fontSize="large" />
          </div>
        </div>
      </Fade>

      <div
        className="offcanvas offcanvas-start offNav"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
        style={{ width: "70%" }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5>
          <button
            type="button"
            className="btn"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{ color: "white" }}
          >
            <CloseOutlined />
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="nav-contents">
            <Link to="/">
              <div>
                <div className="nav-links">
                  <Home /> &nbsp;Home{" "}
                </div>
              </div>
            </Link>
            <Link to="/dashboard">
              <div>
                <div className="nav-links">
                  <Dashboard /> &nbsp;Dashboard{" "}
                </div>
              </div>
            </Link>
            <div onClick={trade}>
              <div className="nav-links">
                <CurrencyExchange />
                &nbsp; Trade{" "}
              </div>
            </div>
            <Link to="/profile">
              <div className="nav-links">
                <AccountCircle /> &nbsp;Profile{" "}
              </div>
            </Link>
            {user?.isAdmin ? (
              <div>
                <Link to="/protected/configure">
                  <div className="nav-links">
                    <Engineering />
                    &nbsp; Configure{" "}
                  </div>
                </Link>
              </div>
            ) : (
              <span></span>
            )}

            <div className="mt-4">
              {authUser && (
                <Button
                  colorScheme="blackAlpha"
                  size="sm"
                  onClick={logout}
                  isLoading={isLoading}
                  rightIcon={<Logout />}
                >
                  Logout
                </Button>
              )}

              {!authUser && (
                <Button
                  colorScheme="blackAlpha"
                  size="sm"
                  onClick={() => nav("/login")}
                  isLoading={isLoading}
                  rightIcon={<Login />}
                >
                  Login
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
