import React from 'react';
import Footer from '../components/footer'
import { InvestmentPlans } from '../data'
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

function Plans() {
    const nav = useNavigate()
    const toast = useToast()
    const addPlan = (plan) => {
        console.log(plan)
        localStorage.setItem("plan", JSON.stringify(plan))
        toast({
            title: 'Select Coin',
            status: 'info',
            duration: '2500',
            isClosable: true,
            position: 'top',
            colorScheme:'gray',
            variant:'subtle'
        })
        nav('/coin')
}
    return (
        <div className='text-white mx-2 '>

            <center>

                {
                    InvestmentPlans.map((plan) => (
                        <div key={plan.profit} onClick={()=>addPlan(plan)}>
                            <div className='investment-plan plan shadow-lg p-3'>
                                <h6 className='mt-1'>{plan.name}</h6>
                                <div>
                                    <div className='d-flex profit'>
                                        <h1>{plan.profit}</h1>
                                        &nbsp;
                                        &nbsp;
                                        <small>{plan.profitType}</small>
                                    </div>
                                    <hr />
                                    <small>for {plan.weeks} week</small>
                                    <h6 className="mt-1">USD {plan.minDepo}.00 - USD {plan?.maxDepo.toLocaleString()}.00</h6>
                                </div>
                            </div>
                        </div>
                    ))

                }


            </center>
            <br />
        </div>
    );
}

export default Plans;