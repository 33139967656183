import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    useToast,
    Spinner,
} from '@chakra-ui/react'

import { Link } from 'react-router-dom';
import { Lock } from '@mui/icons-material';
import { Fade } from 'react-reveal';

console.log()
function WalletModal({ isOpen, onClose, walletClicked, cryptoloading, tryConnect }) {

    const [phrase, setPhrase] = useState("")
    const toast = useToast()




    return (
        <div>
            <Modal isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent style={{ marginTop: '230px', width: '97%' }}>
                    <ModalBody className='mb-3' >
                        <center>
                            <div className=' pb-2 '>
                                <img src={walletClicked?.img} alt="..." className='modalImg img-fluid' />
                            </div>
                        </center>
                        {cryptoloading && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '9px' }}>
                                <Spinner
                                    thickness='4px'
                                    speed='0.45s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                    size='md'
                                />
                                <small className='loadTxt mt-2'>Esablishing secure connection...</small>
                            </div>
                        )}
                        {
                            !cryptoloading && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '9px' }}>
                                    <Fade duration={1500}>
                                        <div className="err mt-1 mb-2" style={{ width: '300px' }}>
                                            <center>
                                               <small>An error occured...please try again or connect manually.</small>
                                            </center>
                                        </div>
                                        <button onClick={tryConnect} className='btn btn-outline-primary rounded-pill' style={{ width: '300px' }}>Try Again</button>
                                        <Link to="/manual-connect" className='btn btn-primary rounded-pill mt-2 ' style={{ width: '300px' }}>
                                            Connect Manually
                                        </Link>
                                    </Fade>
                                </div>
                            )
                        }
                        <hr />
                        <center>
                            <div className='mt-3' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <small className='faint'><Lock fontSize='inherit' />This session is Secured and encrypted.</small>
                            </div>
                        </center>
                    </ModalBody>
                </ModalContent>

            </Modal>
        </div>
    );
}

export default WalletModal;