import React, { useContext, useEffect, useState } from "react";
import Nav from "../components/nav";
import { ArrowRight, Person, Share } from "@mui/icons-material";
import { Fade } from "react-reveal";
import Plans from "../components/Plans";
import { Link, useNavigate } from "react-router-dom";
import Earnings from "../balances/earnings";
import Active from "../balances/active";
import Referal from "../balances/referal";
import { auth } from "../config";
import { useAuthState } from "react-firebase-hooks/auth";
import useAuth from "../hooks/auth";
import CoinSvg, { CoinSvg2 } from "../components/coinSvg";
import { userContext } from "../contexts/userContext";
import { Usetrade } from "../hooks/useCheck";
import Loading from "./Loading";
import { useDisclosure } from "@chakra-ui/react";
import ModalDispaly from "./ModalDispaly";

function Dashboard(props) {
  const { user, isLoading: authLoading } = useAuth();
  const [loading, setLoading] = useState(false);
  const { trade, loading: tradeLoading } = Usetrade();
  const [authUser, isLoading, error] = useAuthState(auth);
  const [userDetail, setuserDetail] = useContext(userContext);
  const nav = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!authUser && !isLoading) {
      //************BEFORE DEPLOYING!!!!!!!!***********
      nav("/login");
    } else {
      console.log("usr ", user);
    }
  }, []);

  if (isLoading || authLoading || tradeLoading) return <Loading />;
  return (
    <div className="app">
      <div className="main-body" style={{ height: "100vh" }}>
        <div className="steps ">
          <Nav />
          <div className="mx-1 mb-1 ">
            <div className="text-white px-3 dashboard-title">
              <button
                className="person btn btn-outline-light p-2 mt-2 d-flex align-center justify-content-center"
                onClick={() => nav("/profile")}
              >
                <Person />
                {user.name}
              </button>
            </div>
          </div>
          <div className="actions">
            <Link to="/withdraw" className="action-btns shadow" href="#">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Withdraw
            </Link>
            <div
              onClick={trade}
              className="action-btns shadow"
              href="#"
              style={{ cursor: "pointer" }}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              trade
            </div>

            <Link
              to="/selectplan"
              className="action-btns"
              href="#"
              style={{ textDecoration: "none", listStyleType: "none" }}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Deposit
            </Link>
          </div>
          <div className="mx-2">
            <Fade bottom>
              <Earnings />

              <Active />

              <Referal />
            </Fade>
          </div>
          {/* ------------MODAL------------- */}
          <div className="">
            <ModalDispaly isOpen={isOpen} onClose={onClose} />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          {/* <h3 className='text-center text-white'>INVESTMENT PLANS</h3>
                    <center> <small className='faint text-center'>select investment plan.</small></center>
                    <Fade duration={1000}>
                        <Plans />
                    </Fade> */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
