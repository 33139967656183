import { useToast } from "@chakra-ui/react";
import useAuth from "./auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../config";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userContext } from "../contexts/userContext";

export const Usecheck = () => {
  const { user, isLoading: authLoading } = useAuth();
  const toast = useToast();
  const check = () => {
    if (user) {
      if (user.trade_updated) {
        const docRef = doc(db, "users", user.id);
        getDoc(docRef).then((doc) => {
          const d = doc.data();
          updateDoc(docRef, {
            trade_updated: false,
          });
        });
        toast({
          title: "Trade Successful",
          description: "Your balance was updated to " + user.activeDeposits,
          variant: "subtle",
          status: "success",
          duration: 2000,
        });
      }
    }
  };
  return { check };
};

export function Usetrade() {
  const nav = useNavigate();
  const toast = useToast();
  const { user, isLoading: authLoading } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userDetail, setuserDetail] = useContext(userContext);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  async function trade() {
    setLoading(true);
    const today = new Date();

    const todays_date = today.getDate();

    const end_now = await user.end_at;
    if (end_now == 0) {
      nav("/trade");
      return;
    }

    if (Date.now() >= end_now) {
      console.log("can trade agian ");
      // setTradeStart(true)

      try {
        const docRef = doc(db, "users", user?.id);
        const data = await getDoc(docRef);
        const d = data.data();
        if (!d?.trade_updated) {
          await updateDoc(docRef, {
            // trade_end_time: 0,
            earnings: parseInt(d?.earnings) + d?.tradeAmount * 1.05,
            trade_updated: true,
          });
          setLoadingUpdate(false);
          toast({
            title: "TRADE ENDED",
            description: "Your total earning have been updated successfully",
            status: "success",
            duration: "4000",
            isClosable: true,
            position: "top",
            variant: "subtle",
          });
          localStorage.removeItem("trade_end_time");
          localStorage.removeItem("trade_day");
          localStorage.removeItem("trade_month");
          setLoading("false");
          nav("/trade");
        } else {
          setLoading("false");
          nav("/trade");
        }
      } catch (error) {
        console.log(error);
        setLoadingUpdate(false);
        setLoading("false");
      }
    } else {
      console.log("can't trade again");
      // setCanTrade(false)
      console.log(today.getHours());
      setLoading("false");

      // setTradeStart(false)
      nav("/trading");
      setLoadingUpdate(false);
    }
  }
  return { trade, loading };
}
