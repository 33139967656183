import React, { useEffect } from "react";
import Intro from "../components/intro";
import Nav from "../components/nav";
import Footer from "../components/footer";
import Details from "../components/details";
import About from "../components/About";

function Home() {
  return (
    <div className="app">
      <div className="main-body">
        <div className="text-white">
          <Nav />
          <Intro />
          <Details />

          <br />

          <br />

          <br />
          <br />
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Home;
