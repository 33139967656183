import { Email, LocationOn, Phone } from "@mui/icons-material";
import React from "react";

function Footer() {
  return (
    <div className="footer text-white py-5 px-2">
      <h2 className="fw-bold">ULTIMATEVEST</h2>
      <br />
      <div>
        <h4 className="fw-bold">REACH OUT TO US</h4>
        <div className="mt-2 faint">
          <div className="d-flex" style={{ marginBottom: "10px" }}>
            <LocationOn />
            &nbsp; Atlanta, USA
          </div>

          {/* <div className="d-flex my-2">
            {" "}
            <Phone />
            &nbsp; call +1 238 756 7732
          </div> */}
          <div className="d-flex">
            <Email />
            &nbsp; support@Ultimatevestplc@gmail.com{" "}
          </div>
        </div>
      </div>
      <div className="my-3">
        <h4 className="fw-bold mb-2">OUR SERVICES</h4>
        <div className="faint">
          <div>Metaverse Development</div>
          <div> Wallet</div>
          <div> White label exchange</div>
          <div>DeFi Decentralized Finance Development</div>
          <div>Dapps Exchange</div>
          <div> IT Maintenance</div>
        </div>
      </div>

      <div>
        <h4 className="fw-bold mb-2">SUBSCRIBE TO OUR NEWSLETTER</h4>
        <div>
          <input
            type="text"
            placeholder="Enter your email"
            className="form-control py-2"
            style={{ width: "70%" }}
          />
          <button className="btn text-white MYbutton">SUBSCRIBE</button>
        </div>
      </div>

      <hr />
      <center>
        <h6> @2021 - 2023 All Rights Reserved.</h6>

        <small className="faint">Terms & Condition | Privacy Policy</small>
      </center>
    </div>
  );
}

export default Footer;
