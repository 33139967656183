export const Wallets = [
    
    { name: "Metamask", img: require("./assets/meta.jpg"), url: "Metamask.com" },
    { name: "Trust Wallet", img: require("./assets/Tw.jpg"), url: "Trust Wallet.com" },
    { name: "Cypto.com", img: require("./assets/defi.png"), url: "Defi .com" },
    { name: "Polka Dot", img: require("./assets/polka.jpeg"), url: "Polkadot.com" },
    { name: "Maiar", img: require("./assets/maiar.png"), url: "Maiar.com" },
    { name: "Equal ", img: require("./assets/equal.jpeg"), url: "equal.com" },
    { name: "Crypto Wallet", img: require("./assets/CW.jpeg"), url: "Crypto Wallet.com" },
    { name: "Kyber ", img: require("./assets/keb.jpeg"), url: "Kyber.com" },
    { name: "Ledger ", img: require("./assets/led.jpeg"), url: "Tron.com" },
    { name: "Phantom ", img: require("./assets/pht.jpeg"), url: "Phantom.com" },
    { name: "Atomic Wallet", img: require("./assets/atomic.jpeg"), url: "Atomic.com" },
    { name: "MEET.ONE", img: require("./assets/meet.jpeg"), url: "Meet.ONE.com" },
    { name: "Rainbow Wallet", img: require("./assets/rainbow.jpeg"), url: "rainbowWallet.com" },
    { name: "Ziliqa", img: require("./assets/zill.png"), url: "Ziliqa.com" },
    { name: "Eidoo", img: require("./assets/eid.png"), url: "Ziliqa.com" },
    { name: "BitPay", img: require("./assets/bp.jpeg"), url: "BitaPy.com" },
    { name: "imToken", img: require("./assets/17_mrrhur.png"), url: "imToken.com" },
    { name: "CYBAVO Wallet", img: require("./assets/cy.png"), url: "imToken.com" },
    { name: "Coin98", img: require("./assets/coin98.png"), url: "coin98.com" },
    { name: "Huobi Wallet", img: require("./assets/huobi.jpeg"), url: "Houbiwallet.com.com" },
    { name: "Ledger Live", img: require("./assets/dger.png"), url: "Ledgetlive.com" },
    { name: "Tron", img: require("./assets/TRN.jpeg"), url: "Tron.com" },
    { name: "Solfare Wallet", img: require("./assets/solfare.png"), url: "solfare.com" },
    { name: "ONTO", img: require("./assets/onto.png"), url: "ONTO.com" },
    { name: "Stepen", img: require("./assets/spen.png"), url: "Stepen.com" },
    { name: "Math Wallet", img: require("./assets/mathwallet.png"), url: "Mathwallet.com" },
]


export const InvestmentPlans = [
    { name: "STARTER PLAN", profit:'50%', weeks: 1, profitType:'profit weekly', minDepo: 30, maxDepo:300 },
    { name: "ELITE PLAN", profit:'55%', weeks: 1, profitType:'profit weekly', minDepo: 301, maxDepo:500 },
    { name: "PREMIUM PLAN", profit:'70%', weeks: 2, profitType:'profit biweekly', minDepo: 501, maxDepo:10000 },
]



export const coins = [
    { name: "USDT",img: require("./assets/us.jpg"), page: '/deposit' },
    // { name: "Bitcoin",img: require("./assets/bit.jpg") , page: '/bit' },
    // { name: "Etherum",img: require("./assets/ethe.jpg") , page: '/eth' },
    // { name: "BNB",img: require("./assets/bnb.jpg") , page: '/bnb' },
    // { name: "solana",img: require("./assets/sol.jpg") , page: '/sol' },
    // { name: "btc",img: require("./assets/bnb.jpg") , page: '/btc' },
]